import React from 'react';
import { Nav } from 'react-bootstrap';
import { account } from '../../config/appwrite';
import appStore from '../../store/appStore';

function MenuGuide(props) {
    const url = window.location.origin

    const btnLogin = async () => {
        await account.createOAuth2Session('google', `${url}/customers`, `${url}`)
            .then((res) => { appStore.setLogin(); console.log('Login successful', res) })
    };

    return (
        <>
            <Nav.Link onClick={btnLogin}>Login by Google</Nav.Link>
        </>
    );
}

export default MenuGuide;
import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import About from "../views/About";
import CustomerList from "../views/auth/CustomerList";
import ServicesList from "../views/auth/ServicesList";
import Home from "../views/Home";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" >
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="customers" >
                <Route index element={<CustomerList />} />
            </Route>
            <Route path="services"  >
                <Route index element={<ServicesList />} />
            </Route>
        </Route>
    )
)
export default router;
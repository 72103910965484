import { action, computed, makeAutoObservable, observable } from "mobx";

class AppStore {
    isLogin = false;

    constructor() {
        makeAutoObservable(this, {
            isLogin: observable,
            checkLogin: computed,
            setLogin: action
        })
    }
    get checkLogin() {
        return this.isLogin
    }

    setLogin() { this.isLogin = !this.isLogin; }
}

const appStore = new AppStore();

export default appStore;
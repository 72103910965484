import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { account } from '../../config/appwrite';
import appStore from '../../store/appStore';

function MenuAuth(props) {
    const navigate = useNavigate();
    const btnLogout = async () => {
        account.deleteSession('current')
        appStore.setLogin()
        navigate('/');
    };

    return (
        <>
            <Nav.Link as={Link} to='/customers'>Customer</Nav.Link>
            <Nav.Link onClick={btnLogout}>Logout</Nav.Link>
        </>
    );
}

export default MenuAuth;
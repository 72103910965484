import React, { useEffect } from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import HeaderCom from '../components/Header/HeaderCom';
import { account } from '../config/appwrite';

function MasterAuth(props) {
    const navigate = useNavigate();

    useEffect(() => {
        account.get().then(() => { }, err => { console.log(err); navigate('/') })
    }, [navigate])

    return (
        <>
            <Helmet>
                <title>{props.title || ''}</title>
            </Helmet>
            <HeaderCom auth={true} />
            <Container className='content-body'>
                <Row>
                    <Col sm={3}>
                        <ListGroup>
                            <ListGroup.Item action as={Link} to='/customers'>Khách hàng</ListGroup.Item>
                            <ListGroup.Item action as={Link} to='/services'>Dịch Vụ </ListGroup.Item>
                            <ListGroup.Item action>Sản phẩm</ListGroup.Item>
                            <ListGroup.Item action>Hợp đồng</ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col sm={9}>
                        {props.children}
                    </Col>
                </Row>

            </Container>
        </>

    );
}

export default MasterAuth;
import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import HeaderCom from '../components/Header/HeaderCom';


function Master(props) {
    return (
        <>
            <Helmet>
                <title>{props.title || ''}</title>
            </Helmet>
            <HeaderCom />
            <Container className='content-body'>
                {props.children}
            </Container>
        </>
    );
}

export default Master;
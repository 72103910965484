import React from 'react';
import MasterAuth from '../../layouts/MasterAuth';

function ServicesList(props) {
    return (
        <MasterAuth title="Dich vu">
            Services List
        </MasterAuth>
    );
}

export default ServicesList;
import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Master from '../layouts/Master';

function Home(props) {
    useEffect(() => { }, []);

    return (
        <Master title="Home">
            Home pa
            <Button />
        </Master>
    );
}

export default Home;
import React from 'react';
import Master from '../layouts/Master';

function About(props) {
    return (
        <Master title="About">

            About
        </Master>
    );
}

export default About;
import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import appStore from '../../store/appStore';
import MenuAuth from './MenuAuth';
import MenuGuide from './MenuGuide';

function HeaderCom(props) {
    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand as={Link} to='/'>React-Bootstrap</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to='/'>Home</Nav.Link>
                        <Nav.Link as={Link} to='/about'>About</Nav.Link>
                    </Nav>
                    <Nav >
                        {(appStore.checkLogin) ? (
                            <MenuAuth />
                        ) : (
                            <MenuGuide />
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default observer(HeaderCom);
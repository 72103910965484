import { Client, Account, Databases } from 'appwrite';

const client = new Client();

client
    .setEndpoint('https://sv.aiviet.click/v1')
    .setProject('641bd01d3b0cd02d334f');

const account = new Account(client);
const databases = new Databases(client);


export {
    account, databases,
}